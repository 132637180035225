import http from '../clients/Axios'

export default class LaporanApotekerService {
  async exportLaporanPenjualanGolongan(periode_from, periode_to, golongan_id, type) {
    return http({
      url: `/report/apoteker/penjualan/golongan/print?filter[periode_from]=${periode_from}&filter[periode_to]=${periode_to}&filter[golongan_id]=${golongan_id}&filter[type]=${type}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
  async exportLaporanReturGolongan(periode_from, periode_to, golongan_id, type) {
    return http({
      url: `/report/apoteker/retur/golongan/print?filter[periode_from]=${periode_from}&filter[periode_to]=${periode_to}&filter[golongan_id]=${golongan_id}&filter[type]=${type}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
  async exportLaporanTriwulanBatch(triwulan, pabrik_id) {
    return http({
      url: `/report/apoteker/triwulan/batch/print?filter[triwulan]=${triwulan}&filter[pabrik_id]=${pabrik_id}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
  async exportLaporanTriwulanBarang(triwulan, pabrik_id) {
    return http({
      url: `/report/apoteker/triwulan/barang/export?filter[triwulan]=${triwulan}&filter[pabrik_id]=${pabrik_id}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
  async printLaporanTriwulanBarang(triwulan, pabrik_id) {
    return http({
      url: `/report/apoteker/triwulan/barang/print?filter[triwulan]=${triwulan}&filter[pabrik_id]=${pabrik_id}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
  async exportLaporanEReport(periode_from, periode_to, golongan_id) {
    return http({
      url: `/report/apoteker/laporan/ereport/print?filter[periode_from]=${periode_from}&filter[periode_to]=${periode_to}&filter[golongan_id]=${golongan_id}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
  async exportLaporanEReportExport(periode_from, periode_to, golongan_id) {
    return http({
      url: `/report/apoteker/laporan/ereport/export?filter[periode_from]=${periode_from}&filter[periode_to]=${periode_to}&filter[golongan_id]=${golongan_id}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
  async exportLaporanSiOdie(periode_from, periode_to, golongan_id, type) {
    return http({
      url: `/report/apoteker/laporan/siodie/print?filter[periode_from]=${periode_from}&filter[periode_to]=${periode_to}&filter[golongan_id]=${golongan_id}&filter[type]=${type}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
  async exportLaporanSiOdieExport(periode_from, periode_to, golongan_id, type) {
    return http({
      url: `/report/apoteker/laporan/siodie/export?filter[periode_from]=${periode_from}&filter[periode_to]=${periode_to}&filter[golongan_id]=${golongan_id}&filter[type]=${type}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
}
